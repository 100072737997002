import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import AxiosActions from "../../AxiosActions";

export const getLTCTestOfferedById = (id: string, config?: any) => {
    return AxiosActions.get(getAxiosInstances().V2, `/test-offered/${id}`, { withCredentials: true, ...config }, true, false);
};

export const postNewTestOffered = (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/test-offered`, dto, { withCredentials: true }, true);
};
export const postNewCollectionSpecification = (
    testOfferedId: number,
    dto: any,
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/test-offered/${testOfferedId}/collection-specification`, dto, config, spinner);
};

export const getCollectionSpecificationById = (
    testOfferedId: number,
    collectionSpecificationId: number,
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/test-offered/${testOfferedId}/collection-specification/${collectionSpecificationId}`, config, spinner);
};

export const updateCollectionSpecificationById = (
    testOfferedId: number,
    collectionSpecificationId: number,
    dto: any,
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse> => {
    return AxiosActions.put(
        getAxiosInstances().V2,
        `/test-offered/${testOfferedId}/collection-specification/${collectionSpecificationId}`,
        dto,
        config,
        spinner,
    );
};

export const deleteCollectionSpecificationById = (
    testOfferedId: number,
    collectionSpecificationId: number,
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/test-offered/${testOfferedId}/collection-specification/${collectionSpecificationId}`, config, spinner);
};

export const updateExistingTestOffered = (testId: number, dto: any): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `/test-offered/${testId}`, dto, { withCredentials: true }, true);
};

export const deleteExistingTestOffered = (testOfferedId: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/test-offered/${testOfferedId}`, {}, true);
};

export const createTestOfferedBulk = <T>(dto: T): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, "/test-offered/bulk", dto, {}, true);
};

export const getCommonIcdCodesByTestOfferedId = (testOfferedId: number, orderId: number, config?: any): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/test-offered/${testOfferedId}/order/${orderId}/medical-code/icd10/tree`, { ...config }, true);
};

export const getTestsOfferedV3 = (dto, config: AxiosRequestConfig, spinner: boolean = false): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V3, `/health/grid/test-offered/available-for-order`, dto, config, spinner);
};
